




const  Gall= [
 { id:1,
  Img:"/gal (01).jpg"
 },

 { id:2,
  Img:"/gal (1).JPG"
 },

 { id:3,
  Img:"/gal (2).jpg"
 },

 { id:4,
  Img:"/gal (3).JPG"
 },

 { id:5,
  Img:"/gal (4).JPG"
 },

 { id:6,
  Img:"/gal (1).jpg"
 },

 { id:7,
  Img:"/gal (5).JPG"
 },

 { id:8,
  Img:"/gal (6).JPG"
 },

 { id:9,
  Img:"/gal (7).jpg"
 },

 { id:10,
  Img:"/gal (8).JPG"
 },
 { id:11,
  Img:"/gal (9).JPG"
 },

 { id:12,
  Img:"/nmi (25).jpg"
 },

 { id:13,
  Img:"/gal (11).JPG"
 },

 { id:14,
  Img:"/gal (12).JPG"
 },

 { id:15,
  Img:"/gal (13).JPG"
 },

 
 { id:16,
  Img:"/gal (14).JPG"
 },
 
 { id:17,
  Img:"/gal (15).JPG"
 },
 
 { id:18,
  Img:"/gal (16).JPG"
 },
 
 { id:19,
  Img:"/gal (17).JPG"
 },
 
 { id:20,
  Img:"/gal (18).JPG"
 },
 
 { id:21,
  Img:"/gal (19).JPG"
 },
 
 { id:22,
  Img:"/gal (21).JPG"
 },
 
 { id:23,
  Img:"/gal (22).JPG"
 },
 
 { id:24,
  Img:"/gal (23).JPG"
 },
 
 { id:25,
  Img:"/gal (24).JPG"
 },
 
 { id:26,
  Img:"/gal (25).JPG"
 },

 
 { id:27,
  Img:"/gal (26).JPG"
 },

 
 { id:28,
  Img:"/gal (41).jpg"
 },

 
 { id:29,
  Img:"/gal2.JPG"
 },

 
 { id:30,
  Img:"/gal (7).jpg"
 },

 
 { id:31,
  Img:"/nmi58.JPG"
 },
 { id:32,
  Img:"/nmi72.JPG"
 },

 { id:33,
  Img:"/nmi86.JPG"
 },
 { id:34,
  Img:"/nmi35.JPG"
 },
 { id:35,
  Img:"/nmi76.JPG"
 },
 { id:36,
  Img:"/nmi22.JPG"
 },

]


export default Gall ;