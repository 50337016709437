const Testimonials= [
 { id:1,
  Video:"/Testimony 1.mp4"
 },

 { id:2,
  Video:"/Testimony 2.mp4"
 },


 { id:3,
  Video:"/tempFile1-1.mp4"
 },

 { id:4,
  Video:"/tempFile2.mp4"
 }

 




]


export default Testimonials ;