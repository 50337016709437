import Info from "./exports";
import Doings from "./doings";
// import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Carousel } from "@material-tailwind/react"
import Testimonials from "./testimonials";
// import { RxDotFilled } from "react-icons/rx";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";



const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="Home ">


      <section className='hero-section bg-yellow-500 space-y-12 pb-6justify-between hover:scale-40 '>
        <div className='flex flex-col container mx-auto  px-6 md:flex-row'>
          <div className=' flex flex-col  mt-10 '>
            <p className='text-lg font-bold text-yellow-400 pt-10 ' >Nicholasmotivates Initiative</p>

            <h1 className='text-4xl font-bold text-white  pt-2'>An Advocacy For the Girl Child Campaign Project;<span className="text-yellow-400"> GIVE OUT A PAD, SAVE A GIRL CHILD</span></h1>
            <p className='text-base text-justify   font-medium text-gray-300 py-5 ' >
            The core mandate of Nicholasmotivates Initiative is to eradicate period poverty, menstrual stigma, and related taboos through targeted advocacy, education, and the provision of essential menstrual hygiene products. We aim to empower vulnerable girls and women by improving access to menstrual hygiene management resources, advocating for policy changes, and fostering community awareness. Our work is focused on creating a society where every girl and woman can manage her menstruation with dignity, without fear, shame, or financial burden. Through our initiatives, we strive to enhance health, education, and overall well-being, ensuring that no girl is left behind due to period poverty.
            </p>
            <div className='flex justify-start'>
              <a href="/contact" className='rounded-md text-white  hover:bg-black hover:text-white  px-4 my-6 py-2 bg-red-600 font-bold  '>Donate Now</a>
            </div>

          </div>
          <img className="mx-auto" src='/girlpad.png' alt='girl'></img>

        </div>

      </section>


      <section className="believe-section py-12">
      <div className="container mx-auto px-6 flex flex-col md:flex-row items-center space-y-10 md:space-y-0 md:space-x-10" >

        <div className=" flex flex-col space-y-2  justify-start">
          <h4 className=' text-2xl text-center f-bold text-gray-800 font-medium md:text-left'>Our Belive</h4>
          <div className="line w-16 h-1 p  place-self-center w-20 md:place-self-start md:w-20 border border-yellow-500 bg-yellow-500"></div>

          <h3 className=' max-w-md text-2xl pt-3 text-center text-gray-800 font-bold md:text-left'>At Nicholasmotivates Initiative, we believe that every girl, woman, female inmate and female gender with disabilities has the right to manage her menstruation with dignity, safety, and respect.</h3>
          <p className='text-base text-center   max-w-md font-medium text-gray-700 py-5 md:text-justify' >
          We are committed to breaking the silence around menstruation, challenging the stigma, and ensuring that no one is marginalized or deprived of opportunities because of period poverty. We believe in the power of education, advocacy, and community engagement to create lasting change and that by providing access to menstrual hygiene products and accurate information, we can empower girls and women to lead healthier, more productive lives. Our belief is rooted in the conviction that menstrual health is a fundamental human right, and we are dedicated to making that right a reality for all.</p>
        </div>

        <div className="image-content md:w-1/2">
          <img className="rounded-lg shadow-lg transition-transform transform hover:scale-105" src="/nmihome.jpg" alt="girl2"></img>

        </div>
      </div>
      </section>


      {/* Service Section */}
      <section className="services-section bg-yellow-50 py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-6"></h2>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {Info.map((item) => (
              <div
                key={item.id}
                className="bg-yellow-500 p-6 rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105"
              >
                <img src={item.Icon} alt="Icon" className="w-16 h-16 mx-auto mb-4" />
                <h3 className="text-lg text-gray-50 font-semibold text-center">{item.headtext}</h3>
                <p className="text-sm text-gray-50 text-center mt-2">{item.bodytext}</p>
              </div>
            ))}
          </div>
        </div>
      </section>


      <section className="cause-section bg-gray-50 py-16">
      <div className="container mx-auto px-6">
      <div className="mb-16">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Our Values and Principles</h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {[
          {
            title: "Empowerment",
            image: "/empowerment.jpg",
            description:
              "Empowering individuals, especially women and girls, to create sustainable change through knowledge, resources, and support.",
          },
          {
            title: "Equity and Inclusivity",
            image: "/equity.jpg",
            description:
              "Promoting equity and inclusivity, ensuring equal opportunities and access for all, regardless of background.",
          },
          {
            title: "Dignity and Respect",
            image: "/dignity.jpg",
            description:
              "Advocating for environments where all individuals can live with dignity, particularly in menstrual health.",
          },
        ].map((value, index) => (
          <div
            key={index}
            className="relative group overflow-hidden rounded-lg shadow-lg"
          >
            <img
              src={value.image}
              alt={value.title}
              className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent opacity-75"></div>
            <div className="absolute bottom-0 p-4">
              <h3 className="text-lg font-bold text-white mb-2">{value.title}</h3>
              <p className="text-sm text-gray-200">{value.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Guiding Principles Section */}
    <div className="relative bg-gray-800 text-white py-12 px-6 mb-16">
      <div className="absolute inset-0 opacity-30 bg-cover bg-center" style={{ backgroundImage: "url('/guiding-principles.jpg')" }}></div>
      <div className="relative z-10 max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Our Guiding Principles</h2>
        <p className="text-lg">
          At Nicholasmotivates Initiative, our guiding principles ensure we create a lasting impact through inclusivity, education, sustainability, and advocacy for change.
        </p>
      </div>
    </div>
    </div>
    </section>


      {/* vid hero */}

      <section className="Vivav   py-4">
        <div className='container mx-auto px-6 py-3 flex flex-col items-center' >
          <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-blue-50 to-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-center text-3xl font-extrabold text-gray-800 leading-relaxed">
              We are passionate about the 
              <span className="text-yellow-500"> reproductive health care </span> 
              and menstrual hygiene management of the girl child.
            </h2>
            <p className="mt-4 text-center text-lg text-gray-700">
              <span className="font-semibold text-yellow-500 uppercase">
                Pads should be given for free,
              </span>{" "}
              as menstruation is an inevitable factor that cannot be altered.
            </p>
          </div>


        </div>

        {/* video secttion */}
        <div className=" mx-auto px-6   container">
          <div className="bg-blue-50  rounded-xl">

            <iframe title="Distributing pads  Section" className="w-full rounded-xl p-2 aspect-video" src="/Unale2.mp4" autoplay="1"></iframe>

          </div>

        </div>


        {/* Grid Section */}
      <div className="container mx-auto px-6 py-10 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
        {Doings.map((doing) => (
          <div
            key={doing.id}
            className="bg-yellow-400 text-center p-4 rounded-lg hover:shadow-2xl hover:bg-blue-50 hover:scale-105 transition"
          >
            <h1 className="text-5xl font-bold">{doing.topN}+</h1>
            <p className="text-gray-800">{doing.btext}</p>
          </div>
        ))}
      </div>
      </section>

      <section className="testimonials py-8 bg-green-50">
        <div className='container mx-auto px-6 ' >

          <div className="flex flex-col items-center justify-center items-center text-center">
            <h4 className="text-3xl font-semibold text-gray-800">Testimonials</h4>
            <div className="line w-16 h-1 bg-yellow-500 mt-2 mb-4"></div>
            <h3 className="max-w-lg text-xl text-gray-600 font-medium">What People are Saying
              About our Services </h3> 
          </div>
          <br />





        </div>



        <div className=" container  px-6 mx-auto">



          <Carousel transition={{ duration: 0.5 }} className="rounded-xl">

            {Testimonials.map(vida => {
              return (


                <div className="bg-blue-50  rounded-xl" key={vida.id}>
                  <iframe title="Distributing pads  Section" className="w-full rounded-xl p-2  aspect-video " src={vida.Video} autoplay="0"></iframe>
                </div>
               )
            })}
          </Carousel>

          


        </div>



      </section>




     





      <section className="Gallery space-y-8 bg-gray-50 py-10">
  <div className="container grid gap-6 mx-auto px-6 justify-center items-center text-center">
    <div className="flex flex-col items-center">
      <h4 className="text-3xl font-semibold text-gray-800">Our Tours</h4>
      <div className="line w-16 h-1 bg-yellow-500 mt-2 mb-4"></div>
      <h3 className="max-w-lg text-xl text-gray-600 font-medium">
        Highlights from our tours and heartfelt moments of giving back.
      </h3>
    </div>
  </div>

  <div className="container mx-auto grid gap-6 px-6 md:grid-cols-3">
    {["/gal2.JPG", "/gal4.jpg", "/gal (22).JPG"].map((src, index) => (
      <div
        key={index}
        className="gallery-item relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transition transform duration-300"
      >
        <img
          src={src}
          alt={`Gallery Item ${index + 1}`}
          className="w-full h-72 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
          <a
            href={src}
            className="text-white bg-yellow-500 px-4 py-2 rounded-md font-bold shadow-md hover:bg-yellow-600 transition"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Full Image
          </a>
        </div>
      </div>
    ))}
  </div>

  <div className="container mx-auto flex justify-center">
    <a
      href="/gallery"
      className="rounded-md text-white bg-yellow-500 hover:bg-yellow-600 px-6 py-3 font-semibold transition"
    >
      View More
    </a>
  </div>
</section>




<section className="bg-white py-12">
  <div className="container mx-auto px-6 space-y-16">
    {/* Key Achievements Section */}
    <div className="text-center space-y-6">
      <h2 className="text-3xl font-extrabold text-gray-800">Key Achievements and Program Development (2022-Present)</h2>
      <p className="text-lg text-gray-600">
        Since its registration, Nicholasmotivates Initiative has made significant strides in its mission to improve menstrual hygiene and advocate for gender equality.
      </p>
    </div>

    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      {/* Achievement Cards */}
      {[
        {
          title: "Sanitary Product Distribution",
          content: "The 'Give Out a Pad, Save a Girl Child' campaign distributes sanitary pads to schools, rural communities, and correctional facilities, benefiting thousands of women and girls.",
          icon: "/icons/distribution.png",
        },
        {
          title: "Educational Workshops and Advocacy",
          content: "Conducting workshops to raise awareness about menstrual hygiene, health, and breaking taboos among young girls and communities.",
          icon: "/icons/education.png",
        },
        {
          title: "Policy and Community Engagement",
          content: "Advocating for policy changes and better menstrual hygiene management standards with local and national stakeholders.",
          icon: "/icons/policy.png",
        },
        {
          title: "Recognition and Partnerships",
          content: "Recognized by governments and NGOs for contributions to social welfare, strengthening impact through collaborative efforts.",
          icon: "/icons/recognition.png",
        },
      ].map((item, index) => (
        <div
          key={index}
          className="bg-yellow-500 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
        >
          <img src={item.icon} alt={item.title} className="w-16 h-16 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-center text-gray-100 mb-2">{item.title}</h3>
          <p className="text-gray-50 text-center">{item.content}</p>
        </div>
      ))}
    </div>

    {/* Purpose Section */}
    <div className="space-y-6">
      <h2 className="text-center text-3xl font-extrabold text-gray-800">Purpose</h2>
      <p className="text-center text-lg text-gray-600">
        Nicholasmotivates Initiative was founded with a clear and impactful purpose: to address menstrual hygiene challenges and promote gender equity.
      </p>

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {/* Purpose Cards */}
        {[
          {
            title: "Improving Menstrual Hygiene",
            content: "Providing access to sanitary products, ensuring girls and women manage their menstrual health with dignity.",
            icon: "/icons/hygiene.png",
          },
          {
            title: "Educating and Raising Awareness",
            content: "Dismantling taboos and misinformation through workshops, seminars, and awareness campaigns.",
            icon: "/icons/education-awareness.png",
          },
          {
            title: "Advocating for Policy Change",
            content: "Integrating menstrual health into broader public health and education policies at all levels.",
            icon: "/icons/advocacy.png",
          },
          {
            title: "Supporting Marginalized Populations",
            content: "Addressing the needs of girls in rural areas, female inmates, and women with disabilities.",
            icon: "/icons/support.png",
          },
          {
            title: "Promoting Gender Equity",
            content: "Challenging cultural norms and creating an inclusive, supportive environment for menstrual health.",
            icon: "/icons/gender-equity.png",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="bg-blue-50 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <img src={item.icon} alt={item.title} className="w-16 h-16 mx-auto mb-4" />
            <h3 className="text-xl text-center font-semibold text-gray-800 mb-2">{item.title}</h3>
            <p className="text-gray-600 text-center">{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
</section>


{/* Purpose Section */}
<section className="purpose-section relative py-16 bg-cover bg-center" style={{ backgroundImage: "url('/purpose-bg.jpg')" }}>
  <div className="overlay bg-black bg-opacity-50 absolute inset-0"></div>
  <div className="container relative mx-auto px-6">
    <div className="text-center text-white mb-12">
      <h2 className="text-4xl font-bold">FOCUS AREA FOR RURAL DEVELOPMENT</h2>
      <p className="text-lg mt-4">
      Nicholasmotivates Initiative is committed to fostering sustainable development in rural communities by addressing key areas that directly impact the quality of life and opportunities for growth.
      </p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 text-white">
      {/* Key Points */}
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Menstrual Hygiene Management (MHM)</h3>
        <p className="mt-3">
        We provide access to sanitary products and education on safe menstrual practices, reducing health risks and ensuring that girls can attend school without interruption.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Sanitation and Health Facilities</h3>
        <p className="mt-3">
        We advocate for the construction and maintenance of adequate sanitation facilities in schools, homes, and public spaces, ensuring that communities have access to clean water and proper hygiene.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Education and Literacy Programs</h3>
        <p className="mt-3">
        We work to improve access to education, particularly for girls, by addressing barriers such as menstrual stigma, lack of sanitary products, and gender-based discrimination.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Women’s Empowerment</h3>
        <p className="mt-3">
        We support initiatives that empower women economically and socially, including skills development, entrepreneurship, and leadership training.
        </p>
      </div>
    </div>
  </div>
</section>




      <section className="bg-blue-50">
        <div className="container px-6  mx-auto ">
          <br />
          <div className="flex flex-col items-center justify-center items-center text-center">
            <h4 className="text-3xl font-semibold text-gray-800">Our Partners</h4>
            <div className="line w-16 h-1 bg-yellow-500 mt-2 mb-4"></div>
            <h3 className="max-w-lg text-xl text-gray-600 font-medium">
            Our Active Partners who believe in the Girl Child Safety Health
            </h3>
          </div>

          <div className="py-5 grid grid-cols-2 gap-3 items-center justify-between md:grid-cols-4">
            {["/Parners.png", "/Parners1.png", "/Parners2.png", "/Parners3.png"].map((src, index) => (
              <div
                key={index}
                className="gallery-items flex justify-center items-center"
              >
                <img
                  src={src}
                  alt={`Partner logo ${index + 1}`}
                  className="w-32 h-32 object-contain"
                />
              </div>
            ))}
          </div>


        </div>

      </section>


    </div>
  );
}

export default Home;
