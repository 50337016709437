import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Values from "./values";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="About bg-gray-50">
      {/* About Section */}
      <section className="about-section py-12 bg-gray-50">
  <div className="container mx-auto px-6 flex flex-col md:flex-row items-center space-y-10 md:space-y-0">
    {/* Text Content */}
    <div className="text-content md:w-1/2 space-y-6" data-aos="fade-right">
      <h1 className="text-4xl font-extrabold text-gray-800 tracking-wide">About Us</h1>
      <p className="text-base text-gray-600 leading-relaxed">
        Nicholasmotivates Initiative is a dedicated non-profit organization committed to empowering vulnerable groups, particularly young girls and women, by addressing critical issues such as period poverty and menstrual hygiene management. Founded with the vision of creating a world where every girl and woman can manage her menstruation with dignity, our organization works tirelessly to break the silence surrounding menstruation, challenge the associated stigma, and ensure access to necessary menstrual products.
        <br />
        <br />
        Through our flagship campaign,{" "}
        <strong className="text-red-600 font-semibold">“GIVE OUT A PAD, SAVE A GIRL CHILD,”</strong> we advocate for menstrual health rights, distribute sanitary pads to those in need, and provide education on menstrual hygiene management. We focus on reaching underserved communities, including rural areas, schools, and prisons, where access to menstrual products is limited, and awareness is often low.
        <br />
        <br />
        Our work is guided by a belief in the power of advocacy, education, and community engagement. We strive to create lasting change by promoting gender equality, improving health outcomes, and ensuring that no girl or woman is left behind due to the lack of access to menstrual hygiene products and education. Through our efforts, we aim to restore dignity, boost confidence, and open up opportunities for girls and women to thrive in their communities.
      </p>
    </div>

    {/* Image Content */}
    <div className="image-content md:w-1/2 flex justify-center" data-aos="fade-left">
      <div className="relative w-3/4">
        <img
          src="/about.JPG"
          alt="About Us"
          className="rounded-lg shadow-lg transition-transform transform hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-tr from-red-600 via-transparent to-red-600 opacity-20 rounded-lg"></div>
      </div>
    </div>
  </div>
</section>

      {/* Our Drive Section */}
      <section className="drive-section bg-red-100 py-12">
        <div className="container mx-auto px-6 flex flex-col md:flex-row items-center space-y-10 md:space-y-0 md:space-x-10">
          <div className="text-content md:w-1/2 space-y-6">
            <h4 className="text-2xl font-bold text-gray-700">Our Drive</h4>
            <div className="w-20 h-1 bg-red-600 mb-4"></div>
            <h3 className="text-xl font-bold text-red-600">GIVE OUT A PAD, SAVE A GIRL CHILD</h3>
            <p className="text-base text-gray-600 leading-relaxed">
              At Nicholasmotivates Initiative, our drive is fueled by a deep commitment to social justice, gender equality, and the belief that every girl and woman deserves to live with dignity. 
              <br />
              <br />
              The stories of young girls missing school, women facing stigma, and entire communities grappling with the lack of access to basic menstrual hygiene products inspire us to take action. We are driven by the vision of a world where menstruation is no longer a barrier to education, health, or opportunities. 
              <br />
              <br />
              Through our advocacy, education, and community engagement efforts, we are determined to break the silence and stigma surrounding menstruation. Our drive is the relentless pursuit of a future where every girl can attend school without fear of menstruation, every woman can work without the shame of period poverty, and every community can support its members with the dignity they deserve.
            </p>
          </div>
          <div className="image-content md:w-1/2">
            <img
              src="/nmi58.JPG"
              alt="Our Drive"
              className="rounded-lg shadow-lg transition-transform transform hover:scale-105"
            />
          </div>
        </div>
      </section>

      {/* Principles Section */}
<section className="principles-section bg-gray-50 py-16">
  <div className="container mx-auto px-6 lg:px-12">
    <h3 className="text-4xl font-extrabold text-gray-800 text-center mb-12">
      What We Stand For
    </h3>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-12">
      {Values.map((val) => (
        <div
          key={val.id}
          className="value-card bg-white rounded-xl shadow-xl p-8 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-2xl"
          data-aos="zoom-in-up"
        >
          <img src={val.Icon} alt={val.headtext} className="w-20 h-20 mb-6" />
          <h5 className="text-2xl font-semibold text-gray-800 mb-4">
            {val.headtext}
          </h5>
          <p className="text-gray-600 text-base leading-relaxed mb-6">
            {val.bodytext}
          </p>
          <div className="h-1 w-16 bg-yellow-500 rounded-full"></div>
        </div>
      ))}
    </div>
  </div>
</section>



      <section className="history-section bg-gray-50 py-16">
  <div className="container mx-auto px-6">
    {/* History Section */}
    <div className="section-header text-center mb-12" data-aos="fade-down">
      <h2 className="text-4xl font-bold text-gray-800 tracking-wide">
        History of <span className="text-red-600">Nicholasmotivates Initiative</span>
      </h2>
      <p className="text-lg text-gray-600 mt-4">
      The journey of Nicholasmotivates Initiative began with a deeply personal and tragic event that shaped its mission. The founder, Mr. Ugwuanyi Nicholason Chiemelum, lost a dear friend named Monica to rape and sexual assault—an unspeakable tragedy rooted in her vulnerability to period poverty. Monica, like many girls facing menstrual health challenges, found herself in a desperate situation. She had no access to menstrual hygiene products and was taken advantage of by a man who manipulated her into relying on him for her menstrual needs.
      </p>
      <p className="text-justify text-gray-600 mt-4"> 
      Monica’s tragic story was not just a personal loss for Nicholas; it became the catalyst for a larger movement. Seeing how period poverty made young girls vulnerable to exploitation, sexual abuse, and shame, Nicholas decided to turn his grief into action. The heartbreaking loss of Monica sparked a powerful advocacy movement, leading to the birth of the “GIVE OUT A PAD, SAVE A GIRL CHILD” campaign project.
      
      The GIVE OUT A PAD, SAVE A GIRL CHILD campaign had its pilot phase in Idah, Kogi State, and from there, it rapidly gained momentum. The goal was simple yet profound: provide sanitary pads to girls and women in need, while educating communities on the importance of menstrual hygiene management. The campaign took root in rural communities, correctional centers, schools, disability centers, and IDP camps, where it aimed to establish pad banks—a sustainable source of sanitary products for girls who would otherwise be left without.

      Through the campaign, Nicholasmotivates Initiative sought to break the cycle of menstrual shame, vulnerability, and absenteeism from school. It became clear that by providing access to sanitary products, the campaign was not just addressing physical needs but also restoring dignity and hope to thousands of girls and women.

      Since its inception, the GIVE OUT A PAD, SAVE A GIRL CHILD, campaign project has continued to grow, expanding into more areas and touching the lives of thousands of beneficiaries. The initiative has established pad banks across its stationed areas, ensuring a continuous supply of menstrual hygiene products to those in need.

      Today, Nicholasmotivates Initiative stands as a beacon of hope in the fight against period poverty, carrying forward the legacy of Monica by protecting vulnerable girls and empowering them with the tools and knowledge to take control of their menstrual health with dignity.

      Monica’s memory lives on through this movement, as her story has become the foundation upon which Nicholasmotivates Initiative builds its mission: “to give every girl the chance to live with confidence and without fear, simply by ensuring access to basic menstrual hygiene products”.</p>
    </div>

    {/* Timeline */}
    <div className="timeline grid grid-cols-1 md:grid-cols-2 gap-12">
      {/* Foundation and Early Beginnings */}
      <div className="timeline-item flex items-start space-x-6" data-aos="fade-right">
        <div className="timeline-marker bg-red-600 rounded-full w-8 h-8 flex items-center justify-center">
          <span className="text-white font-bold">2019</span>
        </div>
        <div>
          <h3 className="text-xl font-bold text-gray-800">Foundation and Early Beginnings</h3>
          <p className="text-gray-600 mt-4">
            Nicholasmotivates Initiative was established in 2019 by Ugwuanyi Nicholason Chiemelum to address menstrual hygiene challenges in marginalized communities. Initial efforts focused on raising awareness and building partnerships.
          </p>
        </div>
      </div>

      {/* Formal Registration and Expansion */}
      <div className="timeline-item flex items-start space-x-6" data-aos="fade-left">
        <div className="timeline-marker bg-red-600 rounded-full w-8 h-8 flex items-center justify-center">
          <span className="text-white font-bold">2021</span>
        </div>
        <div>
          <h3 className="text-xl font-bold text-gray-800">Formal Registration and Expansion</h3>
          <p className="text-gray-600 mt-4">
            Officially registered as a non-profit, Nicholasmotivates launched its flagship campaign, *“Give Out a Pad, Save a Girl Child,”* expanding its reach and impact on rural communities, schools, and correctional facilities.
          </p>
        </div>
      </div>

      {/* Key Achievements and Program Development */}
      <div className="timeline-item flex items-start space-x-6" data-aos="fade-right">
        <div className="timeline-marker bg-red-600 rounded-full w-8 h-8 flex items-center justify-center">
          <span className="text-white font-bold">2022</span>
        </div>
        <div>
          <h3 className="text-xl font-bold text-gray-800">Key Achievements</h3>
          <ul className="list-disc list-inside text-gray-600 mt-4">
            <li>Distributed thousands of sanitary products to underserved populations.</li>
            <li>Conducted educational workshops to dismantle menstrual taboos.</li>
            <li>Engaged in advocacy for policy changes and improved menstrual health standards.</li>
          </ul>
        </div>
      </div>

      {/* Download Button */}
    <div className="mt-12 space-x-6 text-center">
      <a
        href="/Nicholasmotivates-Initiative-Profile.pdf"
        download="Nicholasmotivates-Initiative-Profile.pdf"
        className="inline-block bg-red-500 text-white font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-red-600 transition-colors"
      >
        Download Our Profile
      </a>
    </div>

    </div>
  </div>
</section>

{/* Purpose Section */}
<section className="purpose-section relative py-16 bg-cover bg-center" style={{ backgroundImage: "url('/purpose-bg.jpg')" }}>
  <div className="overlay bg-black bg-opacity-50 absolute inset-0"></div>
  <div className="container relative mx-auto px-6">
    <div className="text-center text-white mb-12">
      <h2 className="text-4xl font-bold">Our Purpose</h2>
      <p className="text-lg mt-4">
        Committed to promoting dignity, equity, and awareness through focused initiatives.
      </p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 text-white">
      {/* Key Points */}
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Improving Menstrual Hygiene</h3>
        <p className="mt-4">
          Enhancing access to sanitary products in underserved areas to ensure dignified menstrual health management.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Educating and Raising Awareness</h3>
        <p className="mt-4">
          Empowering communities through workshops and campaigns to dismantle menstrual taboos.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Advocating for Policy Change</h3>
        <p className="mt-4">
          Influencing local and national policies to integrate menstrual health as a public health priority.
        </p>
      </div>
      <div className="purpose-item" data-aos="fade-up">
        <h3 className="text-2xl font-bold">Supporting Marginalized Populations</h3>
        <p className="mt-4">
          Reaching vulnerable groups to reduce inequality and provide access to menstrual health resources.
        </p>
      </div>
    </div>
  </div>
</section>

{/* Aims and Objectives Section */}
<section className="aims-section py-16 bg-gray-50">
  <div className="container mx-auto px-6">
    <div className="text-center mb-12" data-aos="fade-down">
      <h2 className="text-4xl font-bold text-gray-800">Our Aims & Objectives</h2>
      <p className="text-lg text-gray-600 mt-4">
        Addressing menstrual hygiene and empowering communities through focused objectives.
      </p>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[
        {
          title: "Improvement of Sanitation Facilities",
          description:
            "Advocate for the construction of hygienic sanitation facilities in schools, prisons, and rural communities.",
        },
        {
          title: "Routine Distribution of Sanitary Pads",
          description:
            "Ensure consistent access to sanitary pads for girls in schools, IDP camps, and underserved areas.",
        },
        {
          title: "Improved Mother-Daughter Relationships",
          description:
            "Foster open communication about menstruation and reproductive health through workshops.",
        },
        {
          title: "Male Gender Sensitization",
          description:
            "Engage men and boys to dismantle menstrual taboos and promote gender equality.",
        },
        {
          title: "Addressing Girl Child Vulnerability",
          description:
            "Support vulnerable girls to reduce school dropout rates and prevent exploitation.",
        },
        {
          title: "Promoting Early Sex Education",
          description:
            "Equip children with accurate reproductive health knowledge to reduce stigma and foster healthier attitudes.",
        },
      ].map((aim, index) => (
        <div
          className="aim-item bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow"
          key={index}
          data-aos="zoom-in"
        >
          <h3 className="text-xl font-bold text-gray-800">{aim.title}</h3>
          <p className="text-gray-600 mt-4">{aim.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>







    </div>
  );
};

export default About;