



const Videoclips= [
 
 { id:1,
  Video:"/nmv (1).mp4"
 },

 { id:2,
  Video:"/Unale2.mp4"
 },


 { id:3,
  Video:"/tempFile1.mp4"
 },

 { id:4,
  Video:"/tempFile4.mp4"
 },
 
 { id:5,
  Video:"/tempfile3.mp4"
 },
 
 


 




]

export default Videoclips