
import CauseItems from "./causesitems"
const Causes = () => {
  return (
    <div className="About ">

      

<section className='hero-section bg-yellow-500 py-8  hover:scale-40 '>
        <div className='flex flex-col container mx-auto   md:flex-row'>
          <div className=' flex flex-col mx-auto  items-center justify-center  mt-10 '>
            <p className='text-lg font-bold  place-self-center text-yellow-500 pt-10 ' >Our Causes for actions</p>

            <h1 className='text-4xl text-center max-w-lg font-bold text-white pt-2'>Change is Possible and it comes from the Power Within</h1>
            <p className='  font-medium text-center text-gray-300 py-5 px-3' >
            You can't help the whole world, but
            yon can help a girl child, start listening and taking actions for period poverty elimination</p>

            <div className='flex justify-start my-3'>
              <a href="/" className='rounded-md text-black shadow-sm hover:bg-green-400  hover:text-white  px-4 mt-5 py-2 bg-yellow-500 font-bold  '>Donate Now</a>
            </div>

          </div>
        
        </div>

      </section>


{/* Causes Page */}
<section className="causes-page bg-gray-50 py-16">
  <div className="container mx-auto px-6">

    {/* Header Section */}
    <div className="text-center mb-12">
      <h1 className="text-4xl font-extrabold text-gray-800">
        Causes We Stand For
      </h1>
      <p className="text-lg text-gray-600 mt-4">
        Explore the values, principles, and initiatives driving our mission.
      </p>
    </div>

    {/* Values and Principles Section */}
    <div className="mb-16">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Our Values and Principles</h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {[
          {
            title: "Empowerment",
            image: "/empowerment.jpg",
            description:
              "Empowering individuals, especially women and girls, to create sustainable change through knowledge, resources, and support.",
          },
          {
            title: "Equity and Inclusivity",
            image: "/equity.jpg",
            description:
              "Promoting equity and inclusivity, ensuring equal opportunities and access for all, regardless of background.",
          },
          {
            title: "Dignity and Respect",
            image: "/dignity.jpg",
            description:
              "Advocating for environments where all individuals can live with dignity, particularly in menstrual health.",
          },
        ].map((value, index) => (
          <div
            key={index}
            className="relative group overflow-hidden rounded-lg shadow-lg"
          >
            <img
              src={value.image}
              alt={value.title}
              className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent opacity-75"></div>
            <div className="absolute bottom-0 p-4">
              <h3 className="text-lg font-bold text-white mb-2">{value.title}</h3>
              <p className="text-sm text-gray-200">{value.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Guiding Principles Section */}
    <div className="relative bg-gray-800 text-white py-12 px-6 mb-16">
      <div className="absolute inset-0 opacity-30 bg-cover bg-center" style={{ backgroundImage: "url('/guiding-principles.jpg')" }}></div>
      <div className="relative z-10 max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-6">Our Guiding Principles</h2>
        <p className="text-lg">
          At Nicholasmotivates Initiative, our guiding principles ensure we create a lasting impact through inclusivity, education, sustainability, and advocacy for change.
        </p>
      </div>
    </div>

    {/* Issues of Interest Section */}
    <div className="mb-16">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-8">Issues of Interest</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
        {[
          {
            title: "Menstrual Hygiene Management",
            description:
              "Improving access to sanitary products and menstrual education to enhance health and reduce absenteeism.",
          },
          {
            title: "Sanitation and Health Infrastructure",
            description:
              "Advocating for better sanitation facilities to promote community health.",
          },
          {
            title: "Gender Equality",
            description:
              "Promoting equal opportunities and rights for all genders, focusing on women's empowerment.",
          },
          {
            title: "Early Sex Education",
            description:
              "Providing knowledge on reproductive health and consent to empower young people.",
          },
        ].map((issue, index) => (
          <div
            key={index}
            className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <h3 className="text-xl font-bold text-gray-800 mb-2">{issue.title}</h3>
            <p className="text-gray-600">{issue.description}</p>
          </div>
        ))}
      </div>
    </div>

    {/* Education Section */}
    <div className="bg-yellow-100 py-12 px-6">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Education</h2>
        <p className="text-lg text-gray-700 text-center mb-8">
          Education is at the core of our mission, driving change and creating opportunities for the next generation.
        </p>
        <div className="grid gap-6 md:grid-cols-2">
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-gray-800 mb-2">Access to Quality Education</h3>
            <p className="text-gray-600">
              Ensuring access to education for all, especially for girls in rural and underserved communities.
            </p>
          </div>
          <div className="p-6 bg-white rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-gray-800 mb-2">Menstrual Hygiene Education</h3>
            <p className="text-gray-600">
              Integrating menstrual health into curricula to reduce stigma and promote safety.
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

      



    </div>
  )

}

export default Causes;