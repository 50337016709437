import Gall from "./gall";
import { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Videoclips from "./videoclips";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const images = Gall.map((val) => val.Img); // Extract images for lightbox

    useEffect(() => {
        AOS.init({ duration: 500, once: true });
    }, []);

    return (
        <div className="About bg-yellow-100">

            <section className="Principles bg-green-50">
                <div className="container mx-auto px-6">
                    <h3 className="mt-5 pt-5 text-3xl font-bold text-black text-center">
                        Moments We Have Shared
                    </h3>
                    <p className="py-6 text-gray-600 font-medium text-base text-center">
                        Give out a pad, Save the Girl Child
                    </p>

                    {/* Image Gallery */}
                    <div className="grid gap-6 grid-cols-1 md:grid-cols-3 py-10">
                        {Gall.map((val, index) => (
                            <div
                                className="relative rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transform transition-all duration-300 cursor-pointer"
                                key={val.id}
                                data-aos="fade-up"
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setIsOpen(true);
                                }}
                            >
                                <img
                                    className="rounded-lg w-full h-full object-cover"
                                    src={val.Img}
                                    alt="Shared Moment"
                                />
                                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 flex items-center justify-center text-white text-lg font-bold rounded-lg transition-all">
                                    Click to Preview
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Lightbox for Image Preview */}
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + images.length - 1) % images.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                            }
                        />
                    )}

                    {/* Videos Gallery */}
                    <div className="container mx-auto px-6">
                        <h3 className="mt-5 pt-5 text-3xl font-bold text-black text-center">
                            Videos Gallery
                        </h3>
                        <p className="py-6 text-gray-600 font-medium text-base text-center">
                            Short Clips on Some of Our Campaigns
                        </p>

                        <div className="grid gap-6 grid-cols-1 md:grid-cols-3 py-10">
                            {Videoclips.map((val) => (
                                <div
                                    className="relative rounded-lg shadow-lg hover:shadow-xl hover:scale-105 transform transition-all duration-300"
                                    key={val.id}
                                    data-aos="fade-up"
                                >
                                    <iframe
                                        title="Campaign Clip"
                                        className="w-full rounded-lg aspect-video"
                                        src={val.Video}
                                        allowFullScreen
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Gallery;