const Info= [
 { id:1,
  Icon:"/heart.png",
  headtext:"Helping the Girl Child",
  bodytext:"We strive to create a world where no girl, women, female inmates and female genders with disabilities is held back because they menstruate"
},
{ id:2,
 Icon:"/heart2.png",
 headtext:"Charity Services",
 bodytext:" To show act of kindness by loaning our strenght instead of reminding them their weakness"

},

{ id:3,
 Icon:"/chat.png",
 headtext:"Humaniteria Services",
 bodytext:" We Embrace Equity and inclusivity to the menstrual hygiene management of the girl child, women, female inmates and female genders with disabilities"

}


]


export default Info ;