const Values= [
 { id:1,
  Icon:"/eye.png",
  headtext:"Our Vision",
  bodytext:"The vision of Nicholasmotivates Initiative is to create a world where no girl, woman, female inmates or female genders with disabilities is held back by period poverty, stigma, or lack of access to menstrual health resources. The initiative envisions a society where every menstruating individual can manage their period with dignity, access to essential products, and the education they need to thrive, free from shame or discrimination. Through its efforts, Nicholasmotivates initiative aims to foster gender equality, empower communities, and ensure that every girl has the opportunity to reach her full potential, regardless of her menstrual status."
},
{ id:2,
 Icon:"/mission.png",
 headtext:"Mission",
 bodytext:"The mission of Nicholasmotivates Initiative is to eradicate period poverty and menstrual stigma by providing sustainable access to menstrual health products, education, and resources to vulnerable girls, women, female inmates and female genders with disabilities in underserved communities. The initiative is dedicated to advocating for policy changes, raising awareness through impactful campaigns, and empowering individuals with the knowledge and tools they need to manage their menstrual health with dignity. By fostering community support and building strategic partnerships, Nicholasmotivates initiative aims to create lasting change and ensure that no girl or woman is disadvantaged because of her period."

},


]


export default Values ;